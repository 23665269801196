import React, { useEffect } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import 'materialize-css/dist/css/materialize.min.css'
import M from 'materialize-css'
import image1 from '../../../assets/images/carousel/carousel7.jpg'
import image2 from '../../../assets/images/carousel/carousel4.jpg'
import image3 from '../../../assets/images/carousel/carousel5.jpg'



const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'block',
        // border:'2px solid red',
        marginTop: '50px',
        "@media (max-width: 900px)": {
            marginTop: '48px',
        },
    },
    images: {
        backgroundPosition: 'center',


    }
}))



export default function Carousel() {
    const { wrapper, images } = useStyles()

    useEffect(() => {
        var elems = document.querySelector('.slider');
        M.Slider.init(elems, {
            indicators: true,
            height: 450,
            fullWidth: 100

        })
    }, [])
    return (
        <div className={wrapper} >



            <div className="slider">
                        <ul className="slides">
                            <li >
                                <img src={image1} style={{backgroundPosition:'center', backgroundSize:'cover'}} alt=""/>
                                <div className="caption left-align " style={{marginTop:'80px'}} >
                                    <Typography variant="h3" >Obafemi Awolowo University, Ile-Ife</Typography>
                                    <h5 className="light grey-text text-lighten-3">
                                    Our extensive research programme ranges from the study of the origin of the Universe in the Big Bang to the discovery of planets orbiting other stars.
                                    </h5>
                                </div>
                            </li>
                            <li>
                                <img src={image2} alt=""/>
                                <div className="caption right-align" style={{marginTop:'80px'}}>
                                <Typography variant="h3">Collaboration yield positive result</Typography>
                                    <h5 className="light grey-text text-lighten-3">
                                        Our research works are globally recognized by top-notch journal publishers 
                                    </h5>
                                </div>
                            </li>
                            <li>
                                <img src={image3} alt=""/>
                                <div className="caption center-align" style={{marginTop:'80px'}}>
                                <Typography variant="h3">Exploring </Typography>
                                    <h5 className="light grey-text text-lighten-3">
                                        Here's our small slogan
                                    </h5>
                                </div>
                            </li>
                        </ul>
                    </div>
              

        </div>

    )
}
