import { Container } from '@material-ui/core'
import React from 'react'
import Label from './Label'
import { makeStyles, Typography } from '@material-ui/core'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"

import slider1 from '../../assets/images/carousel/carousel1.jpg'
import slider2 from '../../assets/images/carousel/carousel2.jpg'
import slider3 from '../../assets/images/carousel/carousel3.jpg'
import slider4 from '../../assets/images/carousel/carousel4.jpg'
import slider5 from '../../assets/images/carousel/carousel5.jpg'
import slider6 from '../../assets/images/carousel/carousel6.jpg'
import slider7 from '../../assets/images/carousel/carousel7.jpg'
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"


// import Swiper core and required modules
import SwiperCore, {
  Autoplay, Pagination, Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);
export default function News() {
    return (
        <div style={{ height:'300px'}}>
              <Container>
        <Label text="NEWS" />
        <Swiper breakpoints={{
  "620": {
    "slidesPerView": 2,
    "spaceBetween": 20
  },
  "768": {
    "slidesPerView": 3,
    "spaceBetween": 40
  },
  "920": {
    "slidesPerView": 4,
    "spaceBetween": 40
  },
  "1024": {
    "slidesPerView": 5,
    "spaceBetween": 50
  }
}} navigation={true} autoplay={{ "delay": 3500, "disableOnInteraction": false }} slidesPerView={1} spaceBetween={30} freeMode={true} pagination={{ "clickable": true }} >
          {/*   
                <Swiper spaceBetween={30} centeredSlides={true} autoplay={{
  "delay": 2500,
  "disableOnInteraction": false
}} pagination={{
  "clickable": true
}} navigation={true} className="mySwiper"> */}

          {/* <SwiperSlide  ><img src={slider1}  /></SwiperSlide> */}
          <SwiperSlide style={{ backgroundImage: `url(${slider7})`, height: '200px', width: '200px', backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '150px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '50px' }}>
                <Typography>COVID-19 Vaccine is now available in OAU</Typography>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundImage: `url(${slider6})`, height: '200px', width: '200px', backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '150px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '50px' }}>
                <Typography>COVID-19 Vaccine is now available in OAU</Typography>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundImage: `url(${slider5})`, height: '200px', width: '200px', backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '150px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '50px' }}>
                <Typography>COVID-19 Vaccine is now available in OAU</Typography>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundImage: `url(${slider4})`, height: '200px', width: '200px', backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '150px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '50px' }}>
                <Typography>COVID-19 Vaccine is now available in OAU</Typography>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundImage: `url(${slider3})`, height: '200px', width: '200px', backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '150px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '50px' }}>
                <Typography>COVID-19 Vaccine is now available in OAU</Typography>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundImage: `url(${slider2})`, height: '200px', width: '200px', backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '150px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '50px' }}>
                <Typography>COVID-19 Vaccine is now available in OAU</Typography>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ backgroundImage: `url(${slider1})`, height: '200px', width: '200px', backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '150px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '50px' }}>
                <Typography>COVID-19 Vaccine is now available in OAU</Typography>
              </div>
            </div>
          </SwiperSlide>

          {/* <SwiperSlide><img src={slider1}  /></SwiperSlide> */}
        </Swiper>
      </Container>
            
        </div>
    )
}
