import React from 'react'
import { makeStyles, Container, Typography, Grid } from '@material-ui/core'
import CenteredLabel from './CenteredLabel'
import MostActive from './MostActiveStaff'
import Staffs from './Staffs'


const useStyles = makeStyles(theme => ({
    wrapper: {
        height: 'auto',

        // border:'2px solid green'
    }
}))
export default function Scholar() {
    const Row = props => <Grid container {...props} />
    const Col = props => <Grid item {...props} />
    const { wrapper } = useStyles()
    return (
        <div className={wrapper}>
            <Container>
                <CenteredLabel title="Meet Our Scholars" />

                <Row style={{ marginTop: '20px' }} >
                    <MostActive />
                    <Staffs />
                </Row>
            </Container>



        </div>
    )
}
