import React, {useState} from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));



export default function Selects({ label, onChange, data, errors, value, name }) {


    const classes = useStyles();
    const [open, setOpen] = useState(false)
    

    const handleOpen = ()=>{
        setOpen(true)
    }
    const handleClose = ()=>{
        setOpen(false)
    }
    return (
        <FormControl variant="outlined" size="small" fullWidth >
        <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
        <Select
            labelId="demo-simple-select-outlined-label"
         
            onOpen ={handleOpen}
            onClose={handleClose}
            open={open}
            value={value}
            name={name}
            onChange={onChange}
            label={label}
            error={(errors==true && value=='')? true: false}
        >
            <MenuItem value="">
                <em>None</em>
            </MenuItem>
            {
                data.map(item => (

                    <MenuItem value={item} key={item}>
                        {item}
                    </MenuItem>

                ))
            }
            {/* <MenuItem value="">
                <em>None</em>
            </MenuItem> */}
            {/* <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
    </FormControl>
    )
}
