import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import { Container } from '@material-ui/core'
import { useRef, useState } from "react";
import {Link} from 'react-router-dom'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import image1 from '../../assets/images/carousel/carousel1.jpg'
import image2 from '../../assets/images/carousel/carousel2.jpg'
import image3 from '../../assets/images/carousel/carousel3.jpg'
import image4 from '../../assets/images/carousel/carousel4.jpg'
import image5 from '../../assets/images/carousel/carousel5.jpg'
import image6 from '../../assets/images/carousel/carousel6.jpg'
import image7 from '../../assets/images/carousel/carousel7.jpg'


// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination]);



export default function Staffs() {
    const Col = props => <Grid item {...props} />
    return (
        // <div>
            <Col xs={12} md={9} style={{ height:'400px'}}>

<Swiper
breakpoints={{
  "450": {
    "slidesPerView": 2,
    "spaceBetween": 15
  },
  "620": {
    "slidesPerView": 2,
    "spaceBetween": 15
  },
  "768": {
    "slidesPerView": 2,
    "spaceBetween": 15
  },
  "920": {
    "slidesPerView": 3,
    "spaceBetween": 15,

  },
  "1024": {
    "slidesPerView":3,
    "spaceBetween": 15
  }
}} autoplay={{ "delay": 2500, "disableOnInteraction": false }} navigation={true}  slidesPerView={1} slidesPerColumn={2} spaceBetween={8} pagination={{
  "clickable": true
}} className="mySwiper">
  
  <SwiperSlide style={{ backgroundImage: `url(${image1})`, backgroundPosition:'center ',backgroundSize:'cover', backgroundAttachment:'sticky'}}>
  
  <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
  
              <div style={{ position: 'absolute', top: '112px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '73px' }}>
              <Link to={{
                pathname:`/Ifeoluwa`,
                state:{
                  image:image1
                }
            }} style={{color:'white'}}> <Typography variant="h6">Prof. Lawrence Ifeoluwa</Typography> 
                <Typography variant="subtitle2" style={{margin:'0px', padding:'0px'}}>M.ChB,FWAP</Typography> 
                <Typography variant="subtitle1" style={{ color:'yellow'}}>Dept. of Otorhionocology</Typography> 
                </Link>
              </div>
            
            </div>
           
  </SwiperSlide>
 
  <SwiperSlide style={{ backgroundImage: `url(${image2})`, backgroundPosition:'center ',backgroundSize:'cover', backgroundAttachment:'sticky'}}>
  <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '112px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '73px' }}>
              <Link to={{
                pathname:`/Ifeoluwa`,
                state:{
                  image:image2
                }
            }} style={{color:'white'}}>
                <Typography variant="h6">Prof. Lawrence Ifeoluwa</Typography> 
                <Typography variant="subtitle2" style={{margin:'0px', padding:'0px'}}>M.ChB,FWAP</Typography> 
                <Typography variant="subtitle1" style={{ color:'yellow'}}>Dept. of Otorhionocology</Typography> 
                </Link>
              </div>
            </div>
  </SwiperSlide>
  <SwiperSlide style={{ backgroundImage: `url(${image3})`, backgroundPosition:'center ',backgroundSize:'cover', backgroundAttachment:'sticky'}}>
  <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '112px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '73px' }}>
              <Link to={{
                pathname:`/Ifeoluwa`,
                state:{
                  image:image3
                }
            }} style={{color:'white'}}>
                <Typography variant="h6">Prof. Lawrence Ifeoluwa</Typography> 
                <Typography variant="subtitle2" style={{margin:'0px', padding:'0px'}}>M.ChB,FWAP</Typography> 
                <Typography variant="subtitle1" style={{ color:'yellow'}}>Dept. of Otorhionocology</Typography> 
                </Link>
              </div>
            </div>
  </SwiperSlide>
  <SwiperSlide style={{ backgroundImage: `url(${image4})`, backgroundPosition:'center ',backgroundSize:'cover', backgroundAttachment:'sticky'}}>
  <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '112px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '73px' }}>
               
              <Link to={{
                pathname:`/Ifeoluwa`,
                state:{
                  image:image4
                }
            }} style={{color:'white'}}>
                <Typography variant="h6">Prof. Lawrence Ifeoluwa</Typography> 
                <Typography variant="subtitle2" style={{margin:'0px', padding:'0px'}}>M.ChB,FWAP</Typography> 
                <Typography variant="subtitle1" style={{ color:'yellow'}}>Dept. of Otorhionocology</Typography> 
              </Link>
              </div>
            </div>
  </SwiperSlide>
  <SwiperSlide style={{ backgroundImage: `url(${image1})`, backgroundPosition:'center ',backgroundSize:'cover', backgroundAttachment:'sticky'}}>
  <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '112px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '73px' }}>
              <Link to={{
                pathname:`/Ifeoluwa`,
                state:{
                  image:image1
                }
            }} style={{color:'white'}}>
                <Typography variant="h6">Prof. Lawrence Ifeoluwa</Typography> 
                <Typography variant="subtitle2" style={{margin:'0px', padding:'0px'}}>M.ChB,FWAP</Typography> 
                <Typography variant="subtitle1" style={{ color:'yellow'}}>Dept. of Otorhionocology</Typography> 
             </Link>
              </div>
            </div>
  </SwiperSlide>
  <SwiperSlide style={{ backgroundImage: `url(${image5})`, backgroundPosition:'center ',backgroundSize:'cover', backgroundAttachment:'sticky'}}>
  <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '112px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '73px' }}>
              <Link to={{
                pathname:`/Ifeoluwa`,
                state:{
                  image:image5
                }
            }} style={{color:'white'}}>
                
                <Typography variant="h6">Prof. Lawrence Ifeoluwa</Typography> 
                <Typography variant="subtitle2" style={{margin:'0px', padding:'0px'}}>M.ChB,FWAP</Typography> 
                <Typography variant="subtitle1" style={{ color:'yellow'}}>Dept. of Otorhionocology</Typography> 
                </Link>
              </div>
            </div>
  </SwiperSlide>
  <SwiperSlide style={{ backgroundImage: `url(${image6})`, backgroundPosition:'center ',backgroundSize:'cover', backgroundAttachment:'sticky'}}>
  <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '112px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '73px' }}>
              <Link to={{
                pathname:`/Ifeoluwa`,
                state:{
                  image:image6
                }
            }} style={{color:'white'}}>
                <Typography variant="h6">Prof. Lawrence Ifeoluwa</Typography> 
                <Typography variant="subtitle2" style={{margin:'0px', padding:'0px'}}>M.ChB,FWAP</Typography> 
                <Typography variant="subtitle1" style={{ color:'yellow'}}>Dept. of Otorhionocology</Typography> 
              </Link>
              </div>
            </div>
  </SwiperSlide>
  <SwiperSlide style={{ backgroundImage: `url(${image7})`, backgroundPosition:'center ',backgroundSize:'cover', backgroundAttachment:'sticky'}}>
  <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '112px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '73px' }}>
              <Link to={{
                pathname:`/Ifeoluwa`,
                state:{
                  image:image7
                }
            }} style={{color:'white'}}> 
                <Typography variant="h6">Prof. Lawrence Ifeoluwa</Typography> 
                <Typography variant="subtitle2" style={{margin:'0px', padding:'0px'}}>M.ChB,FWAP</Typography> 
                <Typography variant="subtitle1" style={{ color:'yellow'}}>Dept. of Otorhionocology</Typography> 
             </Link>
              </div>
            </div>
  </SwiperSlide>
  <SwiperSlide style={{ backgroundImage: `url(${image3})`, backgroundPosition:'center ',backgroundSize:'cover', backgroundAttachment:'sticky'}}>
  <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '112px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '73px' }}>
              <Link to={{
                pathname:`/Ifeoluwa`,
                state:{
                  image:image3
                }
            }} style={{color:'white'}}> 
                <Typography variant="h6">Prof. Lawrence Ifeoluwa</Typography> 
                <Typography variant="subtitle2" style={{margin:'0px', padding:'0px'}}>M.ChB,FWAP</Typography> 
                <Typography variant="subtitle1" style={{ color:'yellow'}}>Dept. of Otorhionocology</Typography> 
             </Link>
              </div>
            </div>
  </SwiperSlide>
  <SwiperSlide style={{ backgroundImage: `url(${image5})`, backgroundPosition:'center ',backgroundSize:'cover', backgroundAttachment:'sticky'}}>
  <div style={{ height: '100%', position: 'relative', width: 'inherit' }}>
              <div style={{ position: 'absolute', top: '112px', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: 'inherit', height: '73px' }}>
              <Link to={{
                pathname:`/Ifeoluwa`,
                state:{
                  image:image5
                }
            }} style={{color:'white'}}>
                <Typography variant="h6">Prof. Lawrence Ifeoluwa</Typography> 
                <Typography variant="subtitle2" style={{margin:'0px', padding:'0px'}}>M.ChB,FWAP</Typography> 
                <Typography variant="subtitle1" style={{ color:'yellow'}}>Dept. of Otorhionocology</Typography> 
             </Link>
              </div>
            </div>
  </SwiperSlide>
  </Swiper>

            </Col>
        // </div>
    )
}
