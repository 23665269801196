import React from 'react'
import Copyright from '../../components/Copyright'
export default function Footer() {
    return (
        <div style={{
            height:'50px',
            backgroundColor:'#050D70',
            textAlign:'center',
            paddingTop:'20px'
            
            
        }}>
            <Copyright style={{color:'white'}} />
        </div>
    )
}
