import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'


const useStyles = makeStyles(theme=>({
        wrapper:{
            textAlign:'center',
            // height:'300px',
         

        },

        divider1:{
            backgroundColor:'#050D70',
            marginTop:'0px',
            width:'150px',
            height:'5px',
            display:'inline-block',
            borderRadius:'200px'

        },
        divider2:{
            backgroundColor:'#050D70',
            marginTop:'0px',
            width:'100px',
            height:'5px',
            position:'absolute',
            left:'44.5%',
            textAlign:'center',
            // display:'inline-block',
            borderRadius:'200px'

        },
        divider3:{
            backgroundColor:'#050D70',
            marginTop:'0px',
            width:'150px',
            height:'5px',
            
            // display:'inline-block',
            borderRadius:'200px'

        },
        text:{
            textAlign:'center'
        }
}))
export default function CenteredLabel({title}) {
    const {wrapper, divider1, divider2, divider3, text} = useStyles()
    return (

        <>
        {/* <Typography variant="h3" className={text}>Our Scholars</Typography> */}
        <div className={wrapper}>
            <Typography variant="h3" className={text}>{title}</Typography>
            <div className={divider1}></div>
            {/* <div className={divider2}></div>
            <div className={divider3}></div> */}
        </div>

        </>
    )
}
