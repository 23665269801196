import { Box, Divider } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import Pagination from './Pagination'


export default function Publications() {
    return (
        <div style={{ border: '2px solid gree', height: '320px' }}>
            <Box>

                <Box mb={2}>
                    <Typography variant="h4">Publications</Typography>
                </Box>
                <Box pl={2} mt={1}>
                    
                    <Typography variant="h6">
                        <Link to="/profile" >On the Relativity Principle and the Conclusions Drawn from It</Link>
                    </Typography>
                    <Typography variant="p">
                        Lawrence Ifeoluwa
                    </Typography>
                    <Typography variant="body1" style={{color:'grey'}}>
                        Jahrbuch der Radioaktivität, 1907, pp. 411-462
                    </Typography>
                </Box>
                <Divider />


                <Box pl={2} mt={1}>
                    <Typography variant="h6">
                        <Link to="/profile" >
                            Concerning an Heuristic Point of View Toward the Emission and Transformation of Light</Link>
                    </Typography>
                    <Typography variant="p">Lawrence Ifeoluwa</Typography>
                    <Typography variant="body1" style={{color:'grey'}}>
                        Annalen der Physik, 1905, pp. 132-148
                    </Typography>
                </Box>

                <Divider />


                <Box pl={2} mt={1}>
                    <Typography variant="h6">
                        <Link to="/profile" >On the Movement of Small Particles Suspended in Stationary Liquids Required by the Molecular-Kinetic Theory of Heat</Link>
                    </Typography>
                    <Typography variant="p">
                        Lawrence Ifeoluwa
                    </Typography>
                    <Typography variant="body1" style={{color:'grey'}}>
                        Annalen der Physik, 1905, pp. 549-560
                    </Typography>
                </Box>

                <Divider />


                <Box pl={2} mt={1}>
                    <Typography variant="h6">
                        <Link to="/profile" >Does the Inertia of a Body Depend Upon Its Energy Content?</Link>
                    </Typography>
                    <Typography variant="p">
                        Lawrence Ifeoluwa
                    </Typography>
                    <Typography variant="body1" style={{color:'grey'}}>
                    Annalen der Physik, 1905, pp. 639-641
                    </Typography>
                </Box>


                <Box>
                    <Pagination  />
                </Box>

            </Box>


        </div>
    )
}
