import React from 'react'
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

export default function Copyright({variant, ...rest}) {
    return (
        <Typography style={{}} {...rest} variant={variant || 'body2'} color="textSecondary" >
            © {new Date().getFullYear()} Obafemi Awolowo University | Powered by INTECU
        {/* {'Obafemi Awolowo University © '}
        <Link color="inherit" href="#">
          (INTECU )
        </Link>{' '} */}
        
        {/* {new Date().getFullYear()} */}
        {'.'}
      </Typography>
    )
}
