import React, {useEffect} from 'react'
import  Footer  from '../../parts/categories/Footer'
import ProfileCard from '../../parts/categories/ProfileCard'
import Profiletabs from '../../parts/categories/Profiletabs'
export default function Profile(props) {
    const {image} = props.location.state

    return (
        <div>
            <ProfileCard image = {image}/>
            <Profiletabs image = {image}/>

            <Footer />
        </div>
    )
}





