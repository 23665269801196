import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import slider1 from '../../assets/images/carousel/carousel1.jpg'
import slider2 from '../../assets/images/carousel/carousel2.jpg'
import slider3 from '../../assets/images/carousel/carousel3.jpg'
import slider4 from '../../assets/images/carousel/carousel4.jpg'
import slider5 from '../../assets/images/carousel/carousel5.jpg'
import slider6 from '../../assets/images/carousel/carousel6.jpg'
import slider7 from '../../assets/images/carousel/carousel7.jpg'
import { Box, Paper, Typography } from '@material-ui/core';
import { useEffect } from 'react';


const useStyles = makeStyles(theme => ({
    wrapper:{
        // border:'2px solid red',
        // height:'600px',
        // marginTop:'64px'
        [theme.breakpoints.down('sm')]:{
            marginTop:'55px'
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'64px'
        }
    },
    bgimg:{

       
        backgroundImage:`url(${slider2})`,
        backgroundSize:'cover',
        backgroundPosition:'center',
        height:'200px'
       
    },
    text:{
        // border:'2px solid green',
        
        height:'200px',
        textAlign:'center',
        position:'relative'
    },
    pic:{
       
        backgroundSize:'cover',
        backgroundPosition:'center',
       
        borderRadius:'50%',
        position:'absolute',
        top:'0%',
        left:'50%',
        transform:'translate(-50%, -50%)',

        [theme.breakpoints.down('xs')]:{
            height:'180px',
        width:'180px',
        },
        [theme.breakpoints.only('sm')]:{
            height:'200px',
        width:'200px',
        },
        [theme.breakpoints.up('md')]:{
            height:'210px',
            width:'210px',
        },
        [theme.breakpoints.up('xl')]:{
            height:'220px',
            width:'220px',
        },

    },
    details:{
        
        position:'relative',
        top:'50%',
        // left:'50%',
    },
    paper:{
      
        [theme.breakpoints.up('md')]:{
            paddingBottom:'15px'
        },
       
    }
}));
export default function ProfileCard({image}) {

    const  {wrapper,bgimg, text,pic, details,paper} = useStyles(slider2)

    return (
       <div className={wrapper}>
           <div className={bgimg}></div>
           <Paper className={paper}>
           <div className={text}>
           <div className={pic} style={{ backgroundImage:`url(${image})`}}></div>
          
           <div className={details}>
              
               <Typography variant="h3" style={{textTransform:'uppercase'}}>Lawrence Ifeoluwa</Typography>
                                <Typography variant="h6" style={{margin:'0px', padding:'0px'}}>B.N.Sc, M.ChB, FWAP</Typography> 
                <Typography variant="h5" style={{ color:'#050D70'}}>Dept. of Otorhionocology</Typography>
               
          
           </div>
           
              
               </div>
               </Paper>
           
       </div>

    )
}
