import React from 'react'
import AssignmentIcon from '@material-ui/icons/Assignment';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { List, ListItem, ListItemText, ListItemAvatar } from '@material-ui/core';



import { green } from '@material-ui/core/colors';


const useStyle = makeStyles(theme => (
    {
        rounded: {
            color: '#fff',
            backgroundColor: green[500],
        },
    }
))
export default function ProfileCourses() {


    const { rounded } = useStyle()
    return (

        <div style={{ border: '2px solid gree', height: '320px' }}>

            <Box mb={2}>
                <Typography variant="h4">Lecture Courses</Typography>
            </Box>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={rounded}>
                            <AssignmentIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="h6">CSC201 - Algorithm and Data Structure</Typography>} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={rounded}>
                            <AssignmentIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="h6">CSC305 - Java Programming</Typography>} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={rounded}>
                            <AssignmentIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="h6">CSC415 - Netcentric</Typography>} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={rounded}>
                            <AssignmentIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="h6">CSC211 - Fortran and Matlab</Typography>} />
                </ListItem>
            </List>
        </div>
    )
}
