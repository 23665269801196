import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import ParticlesBg from 'particles-bg'
import logo from '../assets/images/logo/oau-logo.png'
import { FormWrapper, LogoText } from '../parts/Form'
import { Input, VisibleInput } from '../components/FormInputs'
import Buttons from '../components/Button'
import { Send } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import Copyright from '../components/Copyright'

const useStyles = makeStyles(theme => ({

    form: {
        width: '100%',

        padding: '20px'
    },

    input: {
        // margin:'17px'
        marginBottom: '10px',
        textAlign: "center",

    },
    btn: {
        marginTop: '20px'
    },




}))




export default function Login() {

    const Row = props => <Grid container {...props} />
    const Col = props => <Grid item {...props} />

    const classes = useStyles()
    return (
        <>

            <FormWrapper BgEffect={<ParticlesBg type="circle" bg={true} />}>
                <LogoText logo={logo} text="Login Page" />


                <form noValidate autoComplete="off" className={classes.form}>
                    <Col xs={12} className={classes.input}>
                        <Input
                            // onChange={handleChange}
                            //  error={emailErr} 
                            name="email"
                            //   value={email} 
                            label="Email"
                            size="small"
                            variant="outlined" />
                    </Col>


                    <Col xs={12} className={classes.input}>
                        <VisibleInput
                            //  onChange={handleChange}
                            //   error={pwdErr} 
                            name="password"
                            //   value={password} 
                            size="small"
                        />

                    </Col>

                    <Col xs={12} className={classes.input}>

                        <Buttons className={classes.btn} type="submit" size="medium" color="primary" text="Login" endIcon={<Send />} />

                    </Col>



                </form>
                <Col xs={12} className={classes.input}>

                    <Typography>Forgot Password? <Link to={'/reset'}>Reset</Link></Typography>

                </Col>
                <Col xs={12} className={classes.input}>

                    <Copyright />

                </Col>
            </FormWrapper>


        </>
    )
}
