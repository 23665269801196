import React, {useState} from 'react'
import { TextField } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import {IconButton, FormControl } from '@material-ui/core';


export function Input({ variant, type,errors, value, label, onChange, size, name, ...rest}) {
    return (
        <div>
            <TextField {...rest} error={(errors==true && value=='')?true:false} variant={variant} label={label} fullWidth type={type} value={value} size={size} onChange={onChange} name={name} />
        </div>
    )
}




 
export function VisibleInput({size,errors,value,name,onChange}) {
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    
      const [showPassword, setShowPassword] = useState(false)
      const handleClickShowPassword =()=>{
        setShowPassword(!showPassword)
      }
    return (
        <>
        <FormControl   size={size} fullWidth variant='outlined'>
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                   error={(errors==true && value=='')?true:false}
                    color="primary"
                onChange={onChange} value={value} name={name} 
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
            />
        </FormControl>
        </>
    )
}



