import React from 'react'
import Carousel from '../parts/categories/Carousel/Carousel'
// import HomeHeader from '../parts/categories/Navbar/Home_Header'
import Label from '../parts/categories/Label'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import Research from '../parts/categories/Research'
import News from '../parts/categories/News'

import Scholar from '../parts/categories/Scholar'
import ContactUs from '../parts/categories/ContactUs'
import Footer from '../parts/categories/Footer'
export default function Home() {
    const Row = props => <Grid container {...props} />
    const Col = props => <Grid item {...props} />
    return (


        <>


<Carousel />
<Research  />

<News />

<Scholar />

<ContactUs  />

<Footer  />

        </>

        // <Row spacing={2} direction="column">
        //     <Col xs={12}>
        //     <HomeHeader />
        //     </Col>
        //     <Col xs={12}>
        //     <Carousel />
        //     </Col>
        //     <Col xs={12}>
        //     <Label />
        //     </Col>
        // </Row>


        
        
            //  <HomeHeader />
            // <section style={{marginTop:'100px'}}>
            // <Carousel />
            // </section> 
            
        
            
    )
}
