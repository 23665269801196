import React from 'react'

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Biography from './ProfileBiography';
import Publications from './ProfilePublications';
import Courses from './ProfileCourses';
import Assignment from './ProfileAssignment';





const useStyles = makeStyles((theme) => ({
    
    tablink:{
        "&:focus":{
            backgroundColor: "#050D70",
            color:'white'
        }
    }
}));




// class="MuiButtonBase-root MuiTab-root Mui-selected MuiTab-fullWidth"

  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }



  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

export default function Profiletabs({image}) {

    const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  const {tablink} = useStyles()
    return (
        <div style={{ border:'2px solid re'}}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // indicatorColor='primary'
                    // className={classes.button}
              
                    // textColor="white"
                    // variant="fullWidth"
                    aria-label="full width tabs example"
                    centered
                >
                    <Tab  className={tablink} label="Bio" {...a11yProps(0)} />
                    <Tab className={tablink} label="Publications" {...a11yProps(1)} />
                    <Tab className={tablink} label="Coures" {...a11yProps(2)} />
                    <Tab className={tablink} label="Assignment" {...a11yProps(2)} />
                </Tabs>
            </AppBar>

            <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Biography image={image} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <Publications />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Courses />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Assignment />
        </TabPanel>
      </SwipeableViews>
        </div>
    )
}
