import React from 'react'
import { makeStyles, Container, Typography, Grid, Box } from '@material-ui/core'
import contact from '../../assets/images/Contact_us.gif'





// const useStyles = makeStyles(theme => ({
//     wrapper: {
//         height: 'auto',
//         marginTop:'30px',
//         border:'2px solid green',
//         height:'auto'
//     }
// }))


export default function GoogleMap() {
    const Col = props => <Grid item {...props} />
    // const { wrapper } = useStyles()
    return (
        
             <Col xs={12} sm={5} md={4}  style={{backgroundImage:`url(${contact})`, height:'400px', backgroundSize:'cover', backgroundPosition:'center'}}>

{/* <img  src={contact} style={{ height:'inherit', width:'300px'}}/> */}
             </Col>
       
    )
}
