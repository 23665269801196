import React from 'react'
import { FormWrapper, LogoText } from "../../parts/Form";
import { Grid, makeStyles, Typography } from '@material-ui/core'
import ParticlesBg from 'particles-bg'
import logo from '../../assets/images/logo/oau-logo.png'

import { Input, VisibleInput } from '../../components/FormInputs'
import Buttons from '../../components/Button'
import { Add } from '@material-ui/icons'
import Copyright from '../../components/Copyright'
import { Link } from 'react-router-dom'
import Select from '../../components/Select'
import Placeholder from '../../parts/Placeholder';

const useStyles = makeStyles(theme => ({

    form: {
        width: '100%',

        padding: '17px'
    },

    input: {
        // margin:'17px'
        marginBottom: '10px',
        textAlign: "center",

    },
    btn: {
        marginTop: '20px'
    },




}))


const gender = [
    'Male',
    'Female'
]


const designation = [
    'Professor',
    'Reader',
    'Doctor',
    'Engr.',
    'Mr.',
    'Mrs.',
    'Fellow',
    'Other'

]



export default function Signup() {

    const Row = props => <Grid container {...props} />
    const Col = props => <Grid item {...props} />

    const classes = useStyles()
    return (
        <>
            <FormWrapper xs={12} BgEffect={<ParticlesBg type="circle" bg={true} />}>
                <LogoText logo={logo} text="Signup Page" />

                <Placeholder />
                <form noValidate autoComplete="off" className={classes.form}>
                    <Row spacing={2}>
                        <Col xs={12} md={6} >
                            <Input
                                // onChange={handleChange}
                                //  error={emailErr} 
                                name="email"
                                //   value={email} 
                                label="First Name"
                                size="small"
                                variant="outlined" />
                        </Col>


                        <Col xs={12} md={6} >
                        <Input
                                // onChange={handleChange}
                                //  error={emailErr} 
                                name="email"
                                //   value={email} 
                                label="Last Name"
                                size="small"
                                variant="outlined" />

                        </Col>
                    </Row>
                    <Row spacing={2}>
                        <Col xs={12} md={6} >
                            <Input
                                // onChange={handleChange}
                                //  error={emailErr} 
                                name="email"
                                //   value={email} 
                                label="Middle Name"
                                size="small"
                                variant="outlined" />
                        </Col>


                        <Col  xs={12} md={6}>
                        <Input
                                // onChange={handleChange}
                                //  error={emailErr} 
                                name="email"
                                //   value={email} 
                                label="Email"
                                size="small"
                                variant="outlined" />

                        </Col>
                    </Row>

                    <Row spacing={2}>
                        <Col xs={12} md={6} >
                            <Input
                                // onChange={handleChange}
                                //  error={emailErr} 
                                name="email"
                                //   value={email} 
                                label="Password"
                                size="small"
                                variant="outlined" />
                        </Col>


                        <Col xs={12} md={6} >
                        <Input
                                // onChange={handleChange}
                                //  error={emailErr} 
                                name="email"
                                //   value={email} 
                                label="Phone Number"
                                size="small"
                                variant="outlined" />

                        </Col>
                    </Row>

                    <Row spacing={2}>
                        <Col xs={12} md={6} >
                            <Input
                                // onChange={handleChange}
                                //  error={emailErr} 
                                name="email"
                                //   value={email} 
                                label="Faculty"
                                size="small"
                                variant="outlined" />
                        </Col>


                        <Col xs={12} md={6} >
                        <Input
                                // onChange={handleChange}
                                //  error={emailErr} 
                                name="email"
                                //   value={email} 
                                label="Department"
                                size="small"
                                variant="outlined" />

                        </Col>
                    </Row>


                    <Row spacing={2}>
                        <Col xs={12} md={6} >
                            {/* <Input
                                // onChange={handleChange}
                                //  error={emailErr} 
                                name="email"
                                //   value={email} 
                                label="Gender"
                                size="small"
                                variant="outlined" /> */}

                                <Select data={gender}  label="Gender"/>
                        </Col>


                        <Col xs={12} md={6} >
                        {/* <Input
                                // onChange={handleChange}
                                //  error={emailErr} 
                                name="email"
                                //   value={email} 
                                label="Designation"
                                size="small"
                                variant="outlined" /> */}

<Select data={designation}  label="Designation"/>

                        </Col>
                    </Row>


                    <Col xs={12} className={classes.input}>

                        <Buttons className={classes.btn} type="submit" size="medium" color="primary" text="Signup" endIcon={<Add />} />

                    </Col>



                </form>
                <Col xs={12} className={classes.input}>

                    <Typography>Already have an account? <Link to={'/login'}>Login</Link></Typography>

                </Col>
                <Col xs={12} className={classes.input}>

                    <Copyright />

                </Col>
            </FormWrapper>

        </>
    )
}
