import React from 'react'
// import styled from 'styles-component'
import {makeStyles, Typography, Container} from '@material-ui/core'

const useStyles = makeStyles(theme=>({
    wrapper:{
        // marginLeft:"10px",
        // marginRight:'10px'
    },
    divider:{
        
        backgroundColor:'#050D70',
        height:'8px'
    }
}))


export default function Label({text}) {
    const {divider, wrapper} = useStyles()
    return (
        <div >
            
            <Typography variant="h5" style={{fontWeight:'bold'}}>{text}</Typography>
            <div className={divider}></div>

        </div>
    )
}
