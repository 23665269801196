import React from 'react'
import { makeStyles, Typography, Grid, Box } from '@material-ui/core'
import image4 from '../../assets/images/carousel/carousel6.jpg'
import Buttons from '../../components/Button'
const useStyles = makeStyles((theme)=>({
    root:{
        // border:'green solid 2px',
        height:'inherit',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center'
    },
    profile:{
        border:'grey solid 3px',
        height:'230px',
        width:'230px',
        marginTop:'10px',
        alignSelf:'center',
        backgroundImage: `url(${image4})`,
        backgroundSize:'cover',
        borderRadius:'100px',
        // boxShadow:'15px 20px 30px rgba(0,0,0,0.5)'
       
    },
    text:{
        alignSelf:'center',
    }
}))




export default function MostActive() {
    const Col = props => <Grid item {...props} />
    const {root,profile, text} = useStyles()
    return (
        // <div>
             <Col xs={12} md={3}>
                    <Box textAlign="center" >
                        <Typography variant="h4">
                            Most Active Staff
                        </Typography>

                        <div className={root}>
                            <div className={profile}></div>
                            <div className={text}>
                                <Typography variant="h5">Prof. Lawrence Ifeoluwa</Typography>
                                <Typography variant="subtitle2" style={{margin:'0px', padding:'0px'}}>B.N.Sc, M.ChB, FWAP</Typography> 
                <Typography variant="h6" style={{ color:'#050D70'}}>Dept. of Otorhionocology</Typography> 
                <Buttons size="medium" type="button" color="primary" style={{marginTop:'10px', marginBottom:"10px"}}  text="View Profile" />
                            </div>
                        </div>
                    </Box>

                
             </Col>
        // </div>
    )
}
