import { Box, Divider } from '@material-ui/core'
import { Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import Pagination from './Pagination'


import { green } from '@material-ui/core/colors';


const useStyle = makeStyles(theme => (
    {
        rounded: {
            color: '#fff',
            backgroundColor: green[500],
        },
    }
))
export default function ProfileAssignment() {


    const { rounded } = useStyle()
    return (

        <div style={{ border: '2px solid gree', height: '320px' }}>
            <Box>

                <Box mb={2}>
                    <Typography variant="h4">Assignment</Typography>
                </Box>
                <Box pl={2} mt={1}>

                    <Typography variant="h6">
                        <Link to="/profile" >CSC201 - Algorithm and Data Structure</Link>
                    </Typography>
                    <Typography variant="p">
                        Lawrence Ifeoluwa
                    </Typography>
                    <Typography variant="body1" style={{ color: 'grey' }}>
                        30 July, 2021
                    </Typography>
                </Box>
                <Divider />


                <Box pl={2} mt={1}>
                    <Typography variant="h6">
                        <Link to="/profile" >CSC305 - Java Programming</Link>
                    </Typography>
                    <Typography variant="p">
                        Lawrence Ifeoluwa
                    </Typography>
                    <Typography variant="body1" style={{ color: 'grey' }}>
                    12 September, 2021
                    </Typography>
                </Box>

                <Divider />


                <Box pl={2} mt={1}>
                    <Typography variant="h6">
                        <Link to="/profile" >CSC415 - Netcentric</Link>
                    </Typography>
                    <Typography variant="p">
                        Lawrence Ifeoluwa
                    </Typography>
                    <Typography variant="body1" style={{ color: 'grey' }}>
                        12 December, 2021
                    </Typography>
                </Box>

                <Divider />


                <Box pl={2} mt={1}>
                    <Typography variant="h6">
                        <Link to="/profile" >CSC211 - Fortran and Matlab</Link>
                    </Typography>
                    <Typography variant="p">
                        Lawrence Ifeoluwa
                    </Typography>
                    <Typography variant="body1" style={{ color: 'grey' }}>
                        1 August, 2021
                    </Typography>
                </Box>


                <Box>
                    <Pagination />
                </Box>

            </Box>
        </div>
    )
}
