import { FormWrapper } from "../parts/Form";
import { Grid, makeStyles, Typography } from '@material-ui/core'
import ParticlesBg from 'particles-bg'
import logo from '../assets/images/logo/oau-logo.png'
import { Input } from '../components/FormInputs'
import Buttons from '../components/Button'
import { Send } from '@material-ui/icons'
import Copyright from '../components/Copyright'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({

    title: {
        padding: '20px 10px',
        height: 'auto',
        // display: 'flex'
    },
    logo: {
        height: '40px',

    },

    form: {
        width: '100%',
        height: 'auto',
        padding: '8px 20px'
    },

    input: {
        margin: '5px',
        // marginTop: '15px',
        textAlign: "center",

    },
    btn: {
        marginTop: '10px'
    },




}))





export default function Reset() {

    const Row = props => <Grid container {...props} />
    const Col = props => <Grid item {...props} />

    const classes = useStyles()
    return (
        <FormWrapper paperColor="rgba(0,0,0,0.05)" BgEffect={<ParticlesBg type="cobweb" bg={true} />}>

            <Col xs={12}>
                <Row className={classes.title}>
                    <Col xs={2} >
                        <img src={logo} className={classes.logo} />


                    </Col>
                    <Col xs={10} >

                        <Typography variant="h5"  >
                            Reset Password
                        </Typography>

                    </Col>
                    <Col xs={12} >
                        <hr />
                    </Col>

                </Row>
            </Col>

            <form noValidate autoComplete="off" className={classes.form}>
                <Col xs={12} >
                    <Input
                        // onChange={handleChange}
                        //  error={emailErr} 
                        name="email"
                        //   value={email} 
                        label="Email"
                        type="email"
                        placeholder="Enter Your Email address"
                        size="small"
                        variant="outlined" />
                </Col>
                <Col xs={12} className={classes.input}>
                    <Buttons className={classes.btn} type="submit" size="medium" color="primary" text="Submit" endIcon={<Send />} />
                </Col>

            </form>
            <Col xs={12} className={classes.input}>

                <Typography><Link to={'/login'}>Login</Link></Typography>

            </Col>
            <Col xs={12} className={classes.input}>

                <Copyright />

            </Col>
            {/* <Col xs={12} className={classes.title}>
                    <Typography>
                        Reset Password
                    </Typography>
                </Col> */}
        </FormWrapper>
    )
}