import React from 'react'
import { makeStyles, Container, Typography, Grid, Box } from '@material-ui/core'
import { Input, VisibleInput } from '../../components/FormInputs'
import Buttons from '../../components/Button'
import { Send } from '@material-ui/icons'
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
const useStyles = makeStyles(theme => ({

    form: {
        width: '100%',

        padding: '20px',
        border: '2px solid re',
        height: 'inherit'
    },

    input: {
        // margin:'17px'
        marginBottom: '10px',
        textAlign: "center",

    },
    btn: {
        marginTop: '20px'
    },




}))

export default function ContactFields() {
    const Row = props => <Grid container {...props} />
    const Col = props => <Grid item {...props} />
    const classes = useStyles()
    return (

        <Col xs={12} sm={6} md={6} style={{ height: '400px', border: '2px solid gree' }}>




            <Container >

                <form noValidate autoComplete="off" className={classes.form}>

                    <Row spacing={2}>
                        <Col xs={12}>
                            <Input
                                // onChange={handleChange}
                                //  error={emailErr} 
                                name="name"
                                //   value={email} 
                                label="Name"
                                size="small"
                                variant="outlined"

                            />
                        </Col>
                        <Col xs={12}>
                            <Input
                                // onChange={handleChange}
                                //  error={emailErr} 
                                name="email"
                                //   value={email} 
                                label="Email"
                                size="small"
                                variant="outlined"

                            />
                        </Col>
                        <Col xs={12}>
                            <Input
                               id="outlined-multiline-static"
                               label="Message"
                               multiline
                               rows={10}
                               size="large"
                               variant="outlined"
                               style={{height:'100px'}}

                            />
                        </Col>
                        <Col xs={12}>
                            <Buttons
                               
                                text="Submit"
                                size="large"
                                color="primary"
                                type="submit"
                                // endIcon={ArrowForwardIcon}
                            />
                        </Col>
                    </Row>
                </form>
            </Container>





        </Col>

    )
}
