
import React from 'react'
import { Grid, Paper, makeStyles, Box, Typography } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    wrapper: {
        // border: '2px solid red',
        height: '100vh'
    },

    paper: {
        // border: '2px solid blue',
        // height: ()
        borderRadius: '10px',
        backgroundColor: ({paperColor})=>{
            return paperColor ||  "rgba(255,255,255,0.7)"
        } 
    },
    
    logo: {
        height: '80px',


    },
    title: {
        textAlign: 'center',
        marginTop: '2px'
    }

}))




const Row = props => <Grid container {...props} />
const Col = props => <Grid item {...props} />




// CENTERED WRAPPER FOR FORMS

export function FormWrapper({ children,paperColor, BgEffect, elevation, xs, sm, md,lg  }) {

    const classes = useStyles({paperColor})


    return (
        <Row className={classes.wrapper} justifyContent="center" alignItems="center">
          {BgEffect}
            <Col  xs={xs || 11} sm={ sm || 5} md={ md || 4} lg={lg || 3}>
                <Paper className={classes.paper } elevation={elevation || 0}>
                    <Row>
                        {children}
                    </Row>
                </Paper>
            </Col>

        </Row>
    )
}


// LOGO TEXT FOR FORM HEADER
export function LogoText({text, logo}) {

    
    const classes = useStyles()


    return (
        <Col xs={12} >
            <Box textAlign="center" pt={4} mb={0} >
                <img src={logo} className={classes.logo} />
            </Box>

            <Typography variant="h5" className={classes.title}>{text}</Typography>

        </Col>
    )
}
