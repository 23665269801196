import React, { useState, useEffect } from 'react'
import logos from '../../../assets/images/logo/oau-logo.png'
import {
    AppBar,
    Toolbar,
    Typography,
    makeStyles,
    Button,
    IconButton,
    Drawer,
    Link,
    MenuItem,
} from "@material-ui/core";


import MenuIcon from "@material-ui/icons/Menu";

import { Link as RouterLink } from "react-router-dom";



const useStyles = makeStyles(() => ({
    header: {
        //   backgroundColor: "#400CCC",
        backgroundColor: "#050D70",
        paddingRight: "79px",
        paddingLeft: "118px",
        display:'block',
        "@media (max-width: 900px)": {
            paddingLeft: 0,
        },
    },
    // logo: {
    //   fontFamily: "Work Sans, sans-serif",
    //   fontWeight: 600,
    //   color: "#FFFEFE",
    //   textAlign: "left",
    // },
    menuButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        size: "18px",
        //   marginLeft: "38px",
        '&:hover': {
            backgroundColor: '#E3CC25',
            color: 'black'
        }
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    drawerContainer: {
        padding: "20px 30px",
    },
    logo: {
        height: '40px',
        cursor: 'pointer'

    },
}));












const headersData = [
    {
        label: "Home",
        href: "/",
    },
    {
        label: "Research",
        href: "/research",
    },
    {
        label: "Collaborate",
        href: "/collaborate",
    },
    {
        label: "Discovery",
        href: "/discovery",
    },
    {
        label: "Innovations",
        href: "/innovations",
    },
    {
        label: "News",
        href: "/news",
    },
    {
        label: "Login",
        href: "/login",
    },

];




export default function HomeHeader() {

    const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();

    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });

    const { mobileView, drawerOpen } = state;

    const oaulogo = <img src={logos} alt="oaulogo" className={logo} />


    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);


    //   DESKTOP DISPLAY
    const displayDesktop = () => {
        return (
            <Toolbar className={toolbar}>
                {oaulogo}
                <div>{getMenuButtons()}</div>
            </Toolbar>
        );
    };

    //   MOBILE DISPLAY
    const displayMobile = () => {
        const handleDrawerOpen = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: true }));
        const handleDrawerClose = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: false }));


        return (
            <Toolbar>
                <IconButton
                    {...{
                        edge: "start",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen,
                    }}
                >
                    <MenuIcon />
                </IconButton>

                <Drawer
                    {...{
                        anchor: "left",
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                    }}
                >
                    <div className={drawerContainer}>{getDrawerChoices()}</div>
                </Drawer>

                <div>{oaulogo}</div>
            </Toolbar>
        );
    }


    const getDrawerChoices = () => {
        return headersData.map(({ label, href }) => {
            return (
                <Link
                    {...{
                        component: RouterLink,
                        to: href,
                        color: "inherit",
                        style: { textDecoration: "none" },
                        key: label,
                    }}
                >
                    <MenuItem>{label}</MenuItem>
                </Link>
            );
        });
    };

    const femmecubatorLogo = (
        <Typography variant="h6" component="h1" className={logo}>
            {oaulogo}
        </Typography>
    );

    const getMenuButtons = () => {
        return headersData.map(({ label, href }) => {
            return (
                <Button
                    {...{
                        key: label,
                        color: "inherit",
                        to: href,
                        component: RouterLink,
                        className: menuButton,
                    }}
                >
                    {label}
                </Button>
            );
        });
    };


    return (
        <>
        <div>
            <header>
                <AppBar className={header}>
                    {mobileView ? displayMobile() : displayDesktop()}
                </AppBar>
            </header>
        </div>
        </>

    )



}
