
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Login from './pages/Login';
import Reset from './pages/Reset_Password';
import { createTheme, responsiveFontSizes, ThemeProvider,CssBaseline } from '@material-ui/core'
import Signup from './pages/staff-pages/Signup';
import Home from './pages/Home';

import './assets/styles.css'
import Profile from './pages/staff-pages/Profile';
import HomeHeader from './parts/categories/Navbar/Home_Header';
function App() {
  fetch('http://webservices.vebra.com/export/xsd/v11/rightmovetypes.xsd')
  .then(res=>{
    res.json()
  })
  .then(r=>{
    console.log(JSON.stringify(r))
  })
  .catch(err=>{
    console.log(err)
  })
  let theme = createTheme()
  theme = responsiveFontSizes(theme)
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
        <HomeHeader />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/:name' component={Profile} />
            <Route exact path='/login' component={Login} />
         
            <Route exact path='/reset' component={Reset} />
            <Route exact path='/signup' component={Signup} />
          </Switch>
        </Router>
        <CssBaseline />
      </ThemeProvider>

    </>
  );
}

export default App;
