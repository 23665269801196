import React from 'react'
import { Grid, makeStyles, Typography, Box } from '@material-ui/core'
import slider6 from '../../assets/images/carousel/carousel6.jpg'
import Buttons from '../../components/Button'





    const useStyles = makeStyles(theme => ({
        profilePic:{
           
            backgroundSize:'cover',
            backgroundPosition:'center',
            height:'240px',
            borderRadius:'10px'
    
        }
    
    }))
    
    
export default function Biography({image}) {
    const Row = props => <Grid container {...props} />
    const Col = props => <Grid item {...props} />
    const {profilePic} = useStyles(slider6)
    return (
        <div style={{border:'2px solid gree', height:'320px'}}>
            <Row justify="center"  >
                <Col xs={7} sm={3} md={2} className={profilePic} style={{ backgroundImage:`url(${image})`}} ></Col >

                <Col xs={12} sm={6} style={{border:'2px solid gree'}}>
                    <Box ml={4}>
                    <Typography variant="h4">Lawrence Ifeoluwa</Typography>
                   

                    </Box>

                    <Box ml={6} mt={3}>

                    <Typography variant="h5">About Me</Typography>
                    <Typography variant="subtitle1" style={{textAlign:'justify', marginTop:'10px'}} >
                    Lawrence was a British pioneering computer scientist, mathematician, logician, cryptanalyst and theoretical biologist. He was highly influential in the development of computer science, providing a formalisation of the concepts of algorithm and computation with the Turing machine, which can be considered a model of a general purpose computer. Turing is widely considered to be the father of theoretical computer science and artificial intelligence
                    </Typography>

                    <Box mt={1}>
                        <Buttons  size="large" color="primary" text="Download CV" />
                    </Box>
                    </Box>
                    
                </Col>
            </Row>
        </div>
    )
}
 