import React, {useState} from 'react'
import placeholder from '../assets/images/placeholder.png'
import { Grid, makeStyles } from '@material-ui/core'


const useStyles = makeStyles(theme => ({

    image: {
        height: '150px'
    },

    fileUploder: {
        display: 'none'
    },
    wrapper: {
        textAlign: 'center',
        paddingTop: '10px'
    },




}))



//    const handleClick = (e)=>{
//     const  imageUpload = document.getElementsByClassName('ImageUpload')
//         console.log(imageUpload)
//     }


export default function Placeholder() {
    const Row = props => <Grid container {...props} />
    const Col = props => <Grid item {...props} />

    const classes = useStyles()

    const [pics, setPics] = useState(null)


    const handleDisplayPics= (e)=>{
        e.preventDefault()
        let image = document.getElementById('image')
        let validType = ['image/jpeg', 'image/png']
        let type = image.files[0].type

        if(type !=validType[0] && type !=validType[1] ){
            console.log('Invalid File type')
            setPics(null)
        }else{
            setPics(URL.createObjectURL(image.files[0]))
        }
        
        
    }



    return (
        <Col xs={12} className={classes.wrapper}>

            <input type="file" id="image" onChange={handleDisplayPics} className={classes.fileUploder} />
            <label htmlFor="image">
                <img className={classes.image}  src={ pics||`${placeholder}`} />
            </label>

        </Col>
    )
}
