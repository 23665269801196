import React from 'react'
import{ Button} from '@material-ui/core'

export default function Buttons({ text,size, ...rest }) {
    return (
        <div>
            <Button variant="contained"  size={size || "small"} {...rest} >
                {text}
            </Button>

         
        </div>
    )
}
