import React from 'react'
import { makeStyles, Container, Typography, Grid, Box } from '@material-ui/core'
import GoogleMap from './GoogleMap'
import ContactFields from './ContactFields'



const useStyles = makeStyles(theme => ({
    wrapper: {
        height: 'auto',
        marginTop:'30px',
        border:'2px solid gree',
        height:'auto'
    },
    divider:{
        backgroundColor:'#050D70',
        marginTop:'-10px',
        width:'120px',
        height:'5px',
        display:'inline-block',
        borderRadius:'200px',
        marginLeft:'35px'
    },
   
}))




export default function ContactUs() {

    const Row = props => <Grid container {...props} />
    const Col = props => <Grid item {...props} />
    const { wrapper, divider } = useStyles()
    return (
        <div className={wrapper}>
            <Box ml={10} >
                {/* <Typography variant="h3">Get In Touch</Typography> */}
                <Typography variant="h3">Contact Us</Typography>
             
                <div className={divider}></div>
               
                   
            </Box>

            <Row>
                <Col xs={12} sm={1} md={1} />
               <GoogleMap />
                <ContactFields />
            </Row>
        </div>
    )
}
